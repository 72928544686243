//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { SellerModel } from '@/modules/seller/seller-model';
import { FormSchema } from '@/shared/form/form-schema';
import Message from '@/shared/message/message';
import i18n from '@/vueI18n';
import firebase from 'firebase/app';
import 'firebase/firestore'
import { routerAsync } from '@/app-module';
import FirebaseRepository from '@/shared/firebase/firebase-repository'
const { fields } = SellerModel;

const formSchema = new FormSchema([
  fields.name,
  fields.address,
  fields.telephone,
  fields.manager,
  fields.managerName,
  fields.managerPhone,
  fields.isOnlineStore,
  fields.websiteUrl,
  fields.taxFacilityNumber,
  fields.countryId,
  fields.cityId,
  fields.regionId,
  fields.sellerType,
  fields.distributorType,
  fields.city,
  fields.region,
  fields.company,
  fields.area,
  fields.wholesalerId,
]);

export default {
  name: 'app-seller-details-page',
  props: ['pinData'],
  data() {
    return {
      tab: 'wholesaler',
      isWholesaler: true,
      isRetailer: false,
      model: {},
      isPwd: true,
      isConfirmPwd: true,
      confirmPassword: '',
      code: '',
      verificationCode: '',
      step: 2,
      toggle: false,
      //   regionOptions: [],
      areaNum: 1,
      area: [],
      // language: getLanguageCode(),

      countries: [],
      cities: [],
      regions: [],
      countryOptions: [],
      citiesOptions: [],
      regionsOptions: [],
    };
  },
  // async mounted() {
    
  // },
  async created() {
    firebase.firestore().collection('seller').onSnapshot(async (ss) => {
      console.log('docs : ', ss.docs.length);
      await this.getWholesalers()
    })
    // firebase.firestore().collection('country').onSnapshot(async (snapshot) => {
    //   console.log('snapshot : ',snapshot.docs.length);
    // })  

    this.countries = FirebaseRepository.mapCollection(
      await firebase.firestore().collection('country').orderBy('createdAt', 'desc').get()
    )

    // await  this.getCityAndRegion()
    firebase.firestore().collection('city').onSnapshot(async () => {
      this.cities = []
      this.regions = []
      const cities = await firebase.firestore().collection("city").get()
      cities.forEach(async (doc) => {
        let city = doc.data()
        city['id'] = doc.id
        city['regions'] = []
        let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) => {
          let regionInCity = region.data()
          regionInCity['id'] = region.id
          city['regions'].push(regionInCity)
          regionInCity['city'] = city.name
          this.regions.push(regionInCity)
        })
        this.cities.push(city)
      })
      //   this.loading = false
    })

    // this.model.countryId = this.language == 'ar' ? 'مصر' : 'Egypt'
    this.area.push('')
    // this.pinData = (await firebase.firestore().collection('pinCode').where('phoneNumber','==', firebase.auth().currentUser.phoneNumber).limit(1).get()).docs[0].data()

    // if(!this.pinData || !Object.keys(this.pinData).length){
    //   this.model.wholesalerId = this.pinData.wholesalerId
    //   this.model.distributorType = this.pinData.distributorType
    //   console.log(this.wholesaler.find(I => I.id === this.pinData.wholesalerId));
    //   this.model.cityId = this.wholesaler.find(I => I.id === this.pinData.wholesalerId).cityId
    //   this.model.regionId = this.wholesaler.find(I => I.id === this.pinData.wholesalerId).regionId
    // }
    // else{

    if (!this.pinData || !Object.keys(this.pinData).length) {
      console.error('ALI : pin not found');
      const pinDataDoc = (await firebase.firestore().collection('pinCode').where('phoneNumber', '==', firebase.auth().currentUser.phoneNumber).limit(1).get()).docs
      if (pinDataDoc && pinDataDoc.length) this.pinData = pinDataDoc[0].data()
    }
    this.model.telephone = firebase.auth().currentUser.phoneNumber
    this.model.wholesalerId = this.pinData.wholesalerId
    this.model.distributorType = this.pinData.distributorType
    let ws;
    if (this.pinData && Object.keys(this.pinData).length && this.pinData.wholesalerId) ws = (await firebase.firestore().collection('seller').doc(this.pinData.wholesalerId).get()).data()
    // const ws = this.wholesaler.find(I => I.id === this.pinData.wholesalerId)
    if (ws) {
      this.model.cityId = ws.cityId
      this.model.regionId = ws.regionId

    }
    // }
  },
  watch: {
    toggle(newVal) {
      this.model.isOnlineStore = newVal
    },
    tab(newVal) {
      if (newVal === 'wholesaler') {
        this.isRetailer = false
      }
      else if (newVal === 'retailer') {
        this.isWholesaler = false
      }
    },
    isRetailer(newVal) {
      if (newVal) {
        this.tab = 'retailer'
      }
    },
    isWholesaler(newVal) {
      if (newVal) {
        this.tab = 'wholesaler'
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      saveSellerLoading: 'auth/saveSellerLoading',
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
      //   city:'auth/city',
      //   region:'auth/region',
      wholesaler: 'auth/wholesaler'
    }),

    language() {
      return i18n.locale
    },
    countryList() {
      return this.countries.map(item => {
        return {
          label: item.name[this.language],
          value: item.id,
        }
      })
    },
    citiesList() {
      return this.cities.map(item => {
        return {
          label: item.name[this.language],
          value: item.id,
        }
      })
    },
    regionsInCityList() {
      let regions = []
      let selected_city = this.cities.filter(city => city.id == this.model.cityId)

      if (selected_city.length) {
        regions = selected_city[0].regions.map(region => {
          return {
            label: region.name[this.language],
            value: region.id,
          }
        })
      }
      return regions
    },
    wholesalerOptions() {
      return this.wholesaler.map(I => {
        return {
          id: I.id,
          value: I.id,
          label: I.name
        }
      })
    },
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
      getWholesalers: 'auth/getWholesalers',
      getCityAndRegion: 'auth/getCityAndRegion',
      doSignout: 'auth/doSignout',
    }),
    logout() {
      if (firebase.auth().currentUser) {
        firebase.auth().signOut()
      }
      routerAsync().push('/');
    },
    OnClear() {
      this.regionOptions = []
      this.model.regionId = null
    },
    filterCountryFn(val, update) {
      if (val === '') {
        update(() => {
          this.countryOptions = this.countryList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.countryOptions = this.countryList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    filterGovernorateFn(val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.citiesList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.citiesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    filterCitiesFn(val, update) {
      if (val === '') {
        update(() => {
          this.regionsOptions = this.regionsInCityList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.regionsOptions = this.regionsInCityList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    // OnCityChange(){
    //     const arr = this.region.filter(I => I.id === this.model.cityId)
    //     if(arr){
    //         this.regionOptions = arr.map(I => {
    //             return {
    //                 id:I.name['en'],
    //                 label:I.name[localStorage.getItem('language')],
    //                 value:I.name['en'],
    //             }
    //         })
    //     }
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    createValue(val, done) {
      console.log('Value = ', val);
      // specific logic to eventually call done(...) -- or not
      done(val, 'add-unique')

      // done callback has two optional parameters:
      //  - the value to be added
      //  - the behavior (same values of new-value-mode prop,
      //    and when it is specified it overrides that prop –
      //    if it is used); default behavior (if not using
      //    new-value-mode) is to add the value even if it would
      //    be a duplicate
    },
    isPhoneNumberValid(phoneNumber) {
      return /^\+?[0-9]\d{1,14}$/.test(phoneNumber)
    },
    async next() {
      let isValid = await this.$refs.form.validate();
      if (isValid) this.step = this.step + 1
      else Message.error(i18n('messages.noValidForm'))
    },
    addInput() {
      this.area.push('')
    },
    removeInput(index) {
      this.area.splice(index, 1);
    },
    async doSubmit() {
      try {
        let isValid = await this.$refs.form.validate();
        if (isValid) {
          this.model.distributorType = this.pinData.distributorType
          const values = formSchema.cast(this.model)
          values.area = this.area
          values.pinCode = this.pinData.id
          this.$emit('submit', values)
        }
        else Message.error(i18n('messages.noValidForm'))
      } catch (error) {
        Message.error(i18n('messages.error'))
        return;
      }
    },
  },
};
