import { render, staticRenderFns } from "./seller-details.vue?vue&type=template&id=cd0c12ea&scoped=true&"
import script from "./seller-details.vue?vue&type=script&lang=js&"
export * from "./seller-details.vue?vue&type=script&lang=js&"
import style0 from "./seller-details.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./seller-details.vue?vue&type=style&index=1&id=cd0c12ea&lang=scss&scoped=true&"
import style2 from "./seller-details.vue?vue&type=style&index=2&id=cd0c12ea&lang=scss&scoped=true&"
import style3 from "./seller-details.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd0c12ea",
  null
  
)

export default component.exports
import {QForm,QStepper,QStep,QInput,QCheckbox,QTabs,QTab,QSelect,QItem,QItemSection,QRadio,QItemLabel,QStepperNavigation,QBtn,QSpinnerIos,QIcon,QTooltip,QTabPanel,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QStepper,QStep,QInput,QCheckbox,QTabs,QTab,QSelect,QItem,QItemSection,QRadio,QItemLabel,QStepperNavigation,QBtn,QSpinnerIos,QIcon,QTooltip,QTabPanel,QField})
