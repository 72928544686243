//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import sellerDetails from '@/modules/auth/components/seller-details';
import firebase from 'firebase';
import 'firebase/firestore'
import Message from '@/shared/message/message';
import i18n  from '@/vueI18n';
// import {routerAsync} from '@/app-module';
const { fields } = UserModel;


export default {
  name: 'app-signin-page',

  data() {
    return {
      tab:'wholeSeller',
      signUpLoading:false,
      obj:null,
      model: {},
      isPwd: true,
      isConfirmPwd: true,
      confirmPassword:'',
      code:'',
      verificationCode:'',
      step:4,
      maxSteps:4,
      user:null,
      pinCodeLoading: false, 
      sendVerificationCodeLoading: false,
      pinData:{},
      cc:null,
      pn:'',
      lang:'ar',
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الموبايل',
        example: 'مثال :',
      },
    };
  },
  mounted(){
    this.lang = localStorage.getItem('language')
    window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('recaptcha-div', {
              'size': 'invisible',
            });
  },
  async created(){
    
    if(this.$router.currentRoute.fullPath.includes('/auth/details')){
      this.step = 4
    }
    else{
      this.signInAnon()
      await this.getWholesalers()
    }
  },
  components:{
    [sellerDetails.name]:sellerDetails
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
      ananUID:'auth/ananUID'
    }),
    language(){
      return i18n.locale
    },
    
    validPn(){
      if(this.cc && this.cc !== 'EG') return true
      if(this.cc && this.cc === 'EG' && this.pn.length === 10){
        return true
      }
      return false
    },
    fields() {
      return fields;
    },
  },
  watch:{
    language(){
      document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.signup') 
    }
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
      signInAnon:'auth/signInAnon',
      isPinCodeValid:'auth/isPinCodeValid',
      sendVerificationCode:'auth/sendVerificationCode',
      submitSeller:'auth/submitSeller',
      doSignUpWithPhoneNumberCredential:'auth/doSignUpWithPhoneNumberCredential',
      getWholesalers:'auth/getWholesalers',
      doSignout: 'auth/doSignout',
    }),
    onUpdate (payload) {
      this.cc = payload.countryCode
      this.pn = payload.nationalNumber ? payload.nationalNumber.trim() : ''
      if(payload.phoneNumber){
        this.model.phoneNumber = payload.formattedNumber
      }
      else {
        this.model.phoneNumber = ''
      }
    },
    doSubmitSeller(model){
      model.phoneNumber = firebase.auth().currentUser.phoneNumber
      model.pinCode = this.code?this.code:''
      this.submitSeller(model)
    },
    isPhoneNumberValid(phoneNumber){
      console.log(/^\+?[0-9]\d{1,14}$/.test(phoneNumber));
      return /^\+?[0-9]\d{1,14}$/.test(phoneNumber)
    },
    async handleOnComplete(value) {
      if (this.hasNumber(value)) {
        this.signUpLoading = true
        this.code = value
        this.pinData = (await firebase.firestore().collection('pinCode').doc(this.code).get()).data()
        this.signUpLoading = false
      } else {
        this.error = i18n('auth.error.verificationCodeShouldOnlyBeNumbers');
        this.code = ''
        this.signUpLoading = false
      }
    },
    handleOnChange(value){
      if (value.length > 0) {
        this.error = '';
      }
      if (value.length < 8) {
        this.code = '';
      }
    },
    handleOnCompleteVerifiy(value) {
      if (this.hasNumber(value)) {
        this.verificationCode = value
        this.error = '';
      } else {
        this.error = i18n('auth.error.verificationCodeShouldOnlyBeNumbers');
        this.verificationCode = ''
      }
    },
    handleOnChangeVerifiy(value){
      if (value.length > 0) {
        this.error = '';
      }
      if (value.length < 8) {
        this.verificationCode = '';
      }
    },
    hasNumber(myString) {
      return /^\d+$/.test(myString);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    next(){
      if(this.step < this.maxSteps){
        this.step = this.step + 1
      }
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
        if(this.step === 1){
          this.pinCodeLoading = true
          const res = await this.isPinCodeValid(this.code)
          this.pinCodeLoading = false
          if(res) {
            if(!this.pinData){
              this.pinData = res
            }
            return this.next()
          }
          else return
        }
        if(this.step === 2){
          const exsist = (await firebase.firestore().collection('user').where('phoneNumber','==',this.model.phoneNumber).get()).docs
          if(exsist && exsist.length) {
            console.log("ERROR");
            return Message.error(i18n('iam.errors.userAlreadyExists'))
          }
          this.sendVerificationCodeLoading = true
          if(this.model && this.model.phoneNumber && this.isPhoneNumberValid(this.model.phoneNumber)) {
            let appVerifier = window.recaptchaVerifier;
            try {
              this.user = await firebase.auth().currentUser.linkWithPhoneNumber(this.model.phoneNumber,appVerifier)
              this.sendVerificationCodeLoading = false
              if(this.user) return this.next()
              else return
            } catch (error) {
              return
            }
          }
          else {
            return this.sendVerificationCodeLoading = false
          }
        }
        if(this.step === 3){
          firebase.auth().currentUser.delete()
          await this.doSignUpWithPhoneNumberCredential({phoneNumber:this.model.phoneNumber, verificationId: this.user.verificationId, code:this.verificationCode, rememberMe:true })
          firebase.firestore().collection('pinCode').doc(this.code).update({phoneNumber:this.model.phoneNumber})
          this.next()
        }
      } catch (error) {
        return;
      }
    },
    sendMessage(cb){
      let appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(this.model.phoneNumber,appVerifier).then(user => {
          this.user = user
          cb?cb():''
        }).catch(err => {
          Message.error(i18n('firebaseErrors.toManyTries'))
          console.log(err);
        })
    }
  },
};
